import request from '@/utils/request'
//获取运维人员列表
export function  getperson(data) {
    return request({
      method: 'post',
      path: 'webapi/waterSite/getEmployees',
      dataType: 'json',
      data,
  
    })
  }
  //获取站点列表
  export function  getstation(data) {
      return request({
        method: 'post',
        path: 'webapi/waterSite/list',
        dataType: 'json',
        data,
    
      })
    }
  //获取警告信息列表
  export function  getsource(data) {
      return request({
        method: 'post',
        path: 'webapi/waterAlarm/getWarings',
        dataType: 'json',
        data,
    
      })
    }
    //工单指派的接口
    export function  postgongdan(data) {
      return request({
        method: 'post',
        path: 'webapi/waterOrder/orderAlarmsave',
        dataType: 'json',
        data,
    
      })
    }
