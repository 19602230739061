<template>
  <div>
    <Header title="工单指派" color="#666" />
    <van-form
      input-align="right"
      error-message-align="right"
      class="form"
      @submit="onSubmit"
    >
      <van-field
        clickable
        v-model="form.orderName"
        name="source"
        label="工单名称"
        placeholder="请填写"
        :rules="[{ required: true }]"
      />
      <van-field
        clickable
        readonly
        v-model="form.assignName"
        name="source"
        @click="dialogshow = true"
        label="人员分配"
        placeholder="请选择"
        :rules="[{ required: true }]"
      />
      <!-- 人员分配 -->

      <van-dialog
        v-model="dialogshow"
        title="选择人员"
        :show-cancel-button="true"
        @confirm="chooseperson"
        @cancel="qingkong"
      >
        <van-radio-group @change="changes" v-model="result">
          <van-radio v-for="item in peoplelist" :key="item.id" :name="item">{{
            item.userName
          }}</van-radio>
        </van-radio-group>
      </van-dialog>
      <van-field
        clickable
        readonly
        v-model="form.orderType"
        name="source"
        label="工单类型"
        placeholder="请选择"
        :rules="[{ required: true }]"
      />

      <van-field
        clickable
        readonly
        v-model="form.alarmname"
        name="type"
        v-if="form.orderType == '告警'"
        label="告警记录"
        placeholder="请选择"
        is-link
        @click="showSourcePicker = true"
        :rules="[{ required: true }]"
      />
      <van-field
        clickable
        readonly
        v-if="form.orderType != '告警'"
        v-model="form.sitename"
        name="type"
        label="站点名称"
        placeholder="请选择"
        is-link
        @click="showStationPicker = true"
        :rules="[{ required: true }]"
      />
      <van-field
        clickable
        readonly
        v-model="form.orderLevel"
        name="type"
        label="工单等级"
        placeholder="请选择"
       
        :rules="[{ required: true }]"
      />

      <van-field
        readonly
        clickable
        v-model="form.endTime"
        name="endTime"
        label="截至时间"
        placeholder="请选择截至时间"
        is-link
        required
        @click="showEndTimePicker = true"
        :rules="[{ required: true }]"
      />

      <FieldLabel name="内容描述" :required="true" />
      <van-field
        type="textarea"
        v-model="form.orderContent"
        name="content"
        autosize
        rows="2"
        maxlength="500"
        show-word-limit
        placeholder="请填写事件内容"
        clearable
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />
      <FieldLabel name="备注" :required="true" />
      <van-field
        type="textarea"
        v-model="form.note"
        name="content"
        autosize
        rows="2"
        maxlength="500"
        show-word-limit
        placeholder="请填写事件内容"
        clearable
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />

      <!-- <FieldLabel name="图片上传" />
      <van-field
        name="files"
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
      >
        <template #input>
          <van-uploader
            v-model="imglist"
            multiple
            max-count="6"
            :after-read="upload"
          />
        </template>
      </van-field> -->

      <van-button
        size="large"
        class="btn"
        color="#08a9f7"
        :loading="loading"
        loading-text="提交中..."
        native-type="submit"
        >提交</van-button
      >
    </van-form>

    <!-- 所在站点选择 -->
    <van-popup v-model="showStationPicker" position="bottom">
      <van-picker
        title="请选择所在站点"
        show-toolbar
        :columns="stationlist"
        @confirm="selectStation"
        @cancel="showStationPicker = false"
      />
    </van-popup>

    <!-- 工单类型选择 -->
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker
        title="选择工单类型"
        show-toolbar
        :columns="typeList"
        @confirm="selectType"
        @cancel="showTypePicker = false"
      />
    </van-popup>

    <!-- 警告记录选择 -->
    <van-popup v-model="showSourcePicker" position="bottom">
      <van-picker
        title="请选择警告记录"
        show-toolbar
        :columns="sourcelist"
        @confirm="selectSource"
        @cancel="showSourcePicker = false"
      />
    </van-popup>
    <!-- 工单等级选择 -->
    <van-popup v-model="showLevelPicker" position="bottom">
      <van-picker
        title="请选择工单等级"
        show-toolbar
        :columns="levelList"
        @confirm="selectLevel"
        @cancel="showLevelPicker = false"
      />
    </van-popup>

    <!-- 截至日期选择 -->
    <van-popup v-model="showEndTimePicker" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showEndTimePicker = false"
        @confirm="onEndTimeConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { addGongdan } from "@/api/gongdan";
import { uploadImage } from "@/api/common";
import { getperson, getsource, getstation, postgongdan } from "@/api/zhipai";
import Header from "@/components/header";
import {
  Icon,
  Toast,
  Form,
  Cell,
  Field,
  Button,
  Popup,
  Picker,
  Uploader,
  DatetimePicker,
  Dialog,
  RadioGroup,
  Radio,
} from "vant";
import Vue from "vue";
Vue.use(Dialog);
import { validatePhone } from "@/utils/validate";
import FieldLabel from "@/components/fieldLabel";
export default {
  name: "GongdanAdd",
  components: {
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [DatetimePicker.name]: DatetimePicker,
    FieldLabel,
    Header,
  },
  data() {
    this.mobileRules = [
      {
        required: true,
        message: "请输入电话",
      },
      {
        validator: validatePhone,
        message: "电话格式错误",
      },
    ];
    return {
      peoplelist: [],
      result: [],
      loading: false,
      params: {
        parameters: [
          {
            key: "",
            value: "",
          },
        ],
        requestPage: {
          pageNo: 1,
          limit: 50,
        },
      },
      typeList: ["告警", "定期维护","应急派单"],
      levelList: ["一级", "二级", "三级", "四级"],
      stationList: [],
      sourceList: [],
      sourcelist:[],
      form: {
        orderType: "应急派单",
        alarmId: "",
        alarmname:'',
        siteId: "",
        sitename:'',
        orderName: "",
        orderLevel: "一级",
        endTime: "",
        orderContent: "",
        note: "",
        assignId: "",
        assignName: "",
      },
      stationlist:[],
      imglist: [],
      date: new Date(),
      showTypePicker: false,
      showStationPicker: false,
      showSourcePicker: false,
      showEndTimePicker: false,
      showLevelPicker: false,
      dialogshow: false,
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    this.getPerson();
    this.getSource();
   
  },
  mounted() {
     this.getStation();
  },
  updated() {},
  methods: {
    //获取站点列表
    async getStation() {
      let res = await getstation(this.params);
      let arr=res.data.dataResult;
      console.log(arr);
      this.stationList=arr;
      let list=[];
      if (arr) {
        // alert('arr存在')
        arr.forEach(element => {
          list.push(element.name)
        });
      }
      this.stationlist=list
    },
    //获取警告信息列表
    async getSource() {
      let res = await getsource();
      let arr=res.data.dataResult;
      this.sourceList=arr;
      let list=[];
      if (arr) {
      arr.forEach(element => {
        list.push(element.name)
      });
      }
      this.sourcelist=list
      
    },
//获取人员列表
    async getPerson() {
      let res = await getperson();
      console.log(11);
      console.log(res);
      this.peoplelist = res.data;
      // console.log(res);
    },
    //dialog点确定的发生的事情
    chooseperson() {
      this.form.assignId = this.result.userId,
        this.form.assignName = this.result.userName;
    },
    //dialog点取消时候的发生的事情
    qingkong() {
      this.result = {};
    },
    changes() {
    },
    async onSubmit() {
      console.log(this.form);
      let res = await postgongdan(this.form);
      console.log(res);
      this.$router.push({
                path: "/gongdan/index",
              });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },

    onEndTimeConfirm(e) {
      this.form.endTime = this.parseTime(e, "{y}-{m}-{d}");
      this.showEndTimePicker = false;
    },
    selectType(value) {
      this.form.orderType = value;
      this.showTypePicker = false;
    },
    selectStation(value,index) {
      this.form.siteId = this.stationList[index].id;
      this.form.sitename = value;
      this.showStationPicker = false;
    },
    selectSource(value) {
      this.form.siteId = this.stationList[index].id;
      this.form.alarmname = value;
      this.showSourcePicker = false;
    },
    selectLevel(value) {
      this.form.orderLevel = value;
      this.showLevelPicker = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form /deep/ {
  .van-dialog {
    height: 60vh;
    overflow: auto;
    .van-radio {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px;
    }
  }
  .btn {
    width: 175px;
    margin: 20px 100px;
  }
  .van-field__label {
    flex: none;
    width: 90px;
  }
}
</style>