/**
 *  2018/07/12
 */

// 手机号验证
export function validatePhone(str) {
  const reg = /^1[2-9][0-9]{9}$/
  return reg.test(str)
}

// 邮箱验证
export function validateEmail(textval) {
  const urlregex = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  return urlregex.test(textval)
}

// url验证
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

// 小写字母验证
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

// 大写字母验证
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

// 大小写字母验证
export function validateAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

// 正整数验证
export function validateInteger(value) {
  const re = /^[1-9]\d*$/
  return re.test(value)
}

// 正整数验证包含0
export function validateIntegerZero(value) {
  const re = /^([1-9]\d*|[0]{1,1})$/
  return re.test(value)
}

// 价格验证
export function validatePrice(value) {
  const re = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
  return re.test(value)
}

// 百分比验证
export function validatePercent(value) {
  // const re = /^(0|[1-9]\d?|100)$/
  const re = /^(100|([1-9]\d|\d)(.\d{1,2})?)$/
  return re.test(value)
}

// 身份证号验证
export function validateIdCard(value) {
  const re = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return re.test(value)
}

// 0.1-10
export function validateTen(value) {
  const re = /^[1-9]$|^10$|^[0-9](\.\d{1})$/
  return re.test(value)
}